.order-failed-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-failed--logo-container {
    max-width: 250px;
}

.order-failed--logo {
    width: 100%;
}

.order-failed-page--content-container {
    text-align: center;
}
