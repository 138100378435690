.contacts--block {
    box-shadow: var(--box-shadow);
    padding: 25px;
    border-radius: 10px;
    position: relative;
    margin-bottom: 2rem;
    background-color: var(--modal-background);
    font-size: 14px;
}
.contacts--block:last-child {
    margin-bottom: 0;
}
.contacts--map {
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    position: relative;
    margin-bottom: 2rem;
    overflow: hidden;
    height: 400px;
}

.contacts--main-address,
.contacts--main-phone,
.contacts--email,
.contacts--vk,
.contacts--fb,
.contacts--instagram,
.contacts--ok,
.contacts--tg {
    margin-top: 10px;
}
.contacts--main-address svg,
.contacts--main-phone svg,
.contacts--vk svg,
.contacts--fb svg,
.contacts--instagram svg,
.contacts--ok svg,
.contacts--tg svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    vertical-align: middle;
    color: #555;
}

.contacts--email svg {
    width: 22px;
    height: 19px;
    margin-right: 10px;
    vertical-align: middle;
    color: #555;
}
.contacts--today-work {
    font-size: 16px;
}
.today-work--status {
    font-size: 13px;
    margin-top: 5px;
    color: red;
}
.today-work--status.open {
    color: green;
}

.contacts--adress {
    font-size: 14px;
}

.contacts--adress svg,
.contacts--additional-info svg {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    vertical-align: middle;
    color: #555;
}

.contacts--adress .contacts--phone,
.contacts--adress .contacts--schedule,
.contacts--additional-info {
    margin-top: 6px;
}

.contacts--additional-info {
    display: flex;
}

.contacts-zones-info--zones-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
}

.contacts-zones-info:not(:last-child) {
    margin-bottom: 32px;
}

.contacts-zones-info--zone {
    flex-basis: 50%;
}

.contacts-zones-info--zone:not(:last-child) {
    margin-bottom: 24px;
}

.contacts-zones-info--zone .zone-inner-wrapper {
    margin-left: 16px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    background-color: var(--modal-background);
}
.contacts-zones-info--zone .zone-header {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    font-weight: 700;
}
.contacts-zones-info--zone .zone-color {
    flex-shrink: 0;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.contacts-zones-info--zone .zone-body {
    margin-left: 28px;
    display: flex;
}

.zone-body--hr {
    margin: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: var(--divider-color);
    border-bottom-width: 0;
    height: auto;
    border-right-width: thin;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    border-width: 1px;
    margin: 16px 16px 4px 16px;
}

.contacts-zones-info--zone .zone-body--col-title {
    margin-bottom: 4px;
    width: min-content;
    color: #aaa;
    font-size: 14px;
}

.contacts-zones-info--zone .zone-body--col-value {
    font-weight: 700;
    white-space: nowrap;
}

.contacts-zones-info--zone .zone-body hr:first-child {
    display: none;
}

@media (min-width: 899px) and (max-width: 1200px) {
    .zone-body--hr {
        margin: 16px 8px 4px 8px;
    }
    .contacts-zones-info--zone .zone-body {
        margin-left: 0;
    }
}

@media (max-width: 599px) {
    .contacts-zones-info--zone {
        flex-basis: 100%;
    }
}

@media (max-width: 370px) {
    .contacts-zones-info--zone .zone-body {
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
    }
}
