.stories-list--container {
    position: relative;
    height: 250px;
    display: flex;
    margin-bottom: 20px;
}

.stories-list--container .swiper {
    flex-grow: 1;
}

@media (max-width: 899px) {
    .stories-list--container.fullWidthSite {
        margin-top: 10px;
    }
    .stories-list--container {
        height: 119px;
        margin-bottom: 16px;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}
@media (min-width: 900px) {
    .stories-list--container.fullWidthSite .stories-list--container {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
    .stories-list--container.fullWidthSite .stories-list--left-arrow,
    .stories-list--container.fullWidthSite .stories-list--right-arrow {
        display: none;
    }
}
.stories-list--container .swiper-slide {
    width: auto;
}

.stories-list--left-arrow,
.stories-list--right-arrow {
    color: var(--main-color);
    position: absolute;
    top: calc(50% - 20px);
    font-size: 40px;
    cursor: pointer;
    transition: color 0.2s;
}

.stories-list--left-arrow.swiper-button-disabled,
.stories-list--right-arrow.swiper-button-disabled {
    display: none;
}

@media (max-width: 1420px) {
    .stories-list--left-arrow,
    .stories-list--right-arrow {
        display: none;
    }
}

.stories-list--left-arrow {
    left: -30px;
}

.stories-list--right-arrow {
    right: -30px;
}

.stories-list--left-arrow:hover,
.stories-list--right-arrow:hover {
    color: var(--second-color);
}

.stories-slide--read-wrapper {
    padding: 3px;
    border: 3px var(--main-color) solid;
    border-radius: 20px;
    -webkit-transition: border 0.2s;
    transition: border 0.2s;
}

.stories-slide--read-wrapper.viewed {
    border: 3px transparent solid;
}

@media (max-width: 1420px) {
    .stories-slide--read-wrapper {
        padding: 2px;
        border: 2px var(--main-color) solid;
    }
    .stories-slide--read-wrapper.viewed {
        border: 2px transparent solid;
    }
}

.stories-slide--container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 16px;
    height: 100%;
    width: 190px;
    cursor: pointer;
}

@media (max-width: 899px) {
    .stories-slide--container {
        width: 89px;
    }
}

.stories-slide--container img {
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    max-width: none;
    object-fit: cover;
}

.stories--dialog {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.stories--dialog img {
    width: 100%;
    z-index: 1;
}

.stories--dialog video {
    width: 100%;
    z-index: 1;
}

.stories--modal-close {
    position: absolute !important;
    z-index: 1000;
    right: 10px;
    top: 25px;
    font-size: 16px;
    padding: 4px !important;
    width: 28px !important;
    height: 28px !important;
    background-color: #fff !important;
}

.stories--modal-close svg {
    width: 22px;
    height: 22px;
    color: var(--main-color);
}

.see-more-collapsed--container,
.stories--promocode-btn-container {
    position: absolute;
    margin: auto;
    bottom: 0px;
    z-index: 9999;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 0 22px 22px 22px;
}

.stories--promocode-btn-container button {
    width: 100%;
    height: 54px;
    border-radius: 30px !important;
    font-size: 20px !important;
}

.see-more-collapsed--button {
    width: 100%;
    height: 54px;
    border-radius: 30px !important;
    font-size: 20px !important;
}

.see-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: var(--background-color);
}

.see-more--inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
}

.see-more--inner-wrapper::-webkit-scrollbar {
    width: 6px;
}
.see-more--inner-wrapper::-webkit-scrollbar-track {
    background: #f2f5f7;
}
.see-more--inner-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.see-more--inner-link-container {
    padding-top: 20px;
    width: 100%;
}

.see-more--inner-wrapper h2 {
    margin: 0 0 20px 0;
}

.see-more--close-container {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    transition: color 0.2s;
}

.see-more--close-container:hover {
    color: var(--main-color);
}

.see-more--close-container svg {
    font-size: 24px;
}

.see-more--content-body {
    padding-bottom: 16px;
}

.stories--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.stories--background {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-size: cover;
    box-sizing: border-box;
    filter: blur(100px);
}

.stories--content {
    width: 100%;
    height: 100%;
}

.panels-container {
    position: absolute;
    height: inherit;
    width: inherit;
    display: flex;
}

.stories-stack-container {
    display: flex;
    flex-direction: column;
    background: rgb(17, 17, 17);
    border-radius: 20px;
    position: relative;
    width: 432px;
    height: 768px;
    overflow: hidden;
}

@media (max-width: 899px) {
    .stories-stack-container {
        border-radius: 0px;
    }
}

.progress-bar-container {
    display: flex;
    justify-content: center;
    max-width: 100%;
    position: absolute;
    width: 98%;
    padding: 10px 10px 10px;
    align-self: center;
    z-index: 1001;
    filter: drop-shadow(rgb(34, 34, 34) 0px 1px 8px);
}

.story-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navigation-panels-container {
    position: absolute;
    height: inherit;
    width: inherit;
    display: flex;
}

.navigation-panels-container .left-panel,
.navigation-panels-container .right-panel {
    width: 50%;
    z-index: 999;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    touch-action: none;
}

.stories-swiper {
    margin: 0;
    height: 100%;
}

.stories-swiper .swiper-slide {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
