.checkout--user,
.checkout--delivery,
.checkout--order-time {
    margin-bottom: 3rem;
}
.checkout--choose-type-panel {
    background: #f2f2f2;
    border-radius: 10px;
    padding: 1.5rem;
}

.checkout--delivery {
    box-shadow: var(--box-shadow);
    padding: 25px;
    border-radius: 10px;
    margin-top: 1.5rem;
    background-color: var(--modal-background);
}

.checkout--self-address-panel .adress-schdedule {
    color: #666;
}

.custom-radio.new-address .MuiFormControlLabel-label {
    font-weight: bold;
}

.checkout--delivery .custom-radio {
    height: auto;
}

.checkout--delivery
    .checkout--self-address-panel
    .custom-radio:not(:last-child) {
    margin-bottom: 8px;
}

.checkout--delivery .checkout--address-panel .custom-radio {
    margin-bottom: 8px;
}

.checkout--delivery .checkout--address-panel .custom-radio .user-address-label {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-left: -5px;
    line-height: 1.5;
}

.checkout--delivery
    .checkout--address-panel
    .custom-radio
    .user-address-label
    .with-label {
    color: #ccc;
    font-size: 13px;
}

.checkout--delivery .checkout--address-panel .show-more {
    margin-bottom: 16px;
    color: var(--main-color);
    cursor: pointer;
    width: fit-content;
    border-bottom: 1px dashed var(--main-color);
}

.checkout--delivery .MuiRadio-root {
    padding: 5px 9px 9px 9px;
    height: fit-content;
}
.checkout--subtotal-price,
.checkout--promocode-total,
.checkout--auto-discount-container {
    font-size: 18px;
    font-weight: bold;
}
.checkout--subtotal-price,
.checkout--promocode-total,
.checkout--auto-discount-container {
    display: flex;
    justify-content: space-between;
}

.checkout--auto-discount-name,
.result-delivery--title-container {
    display: flex;
    align-items: center;
}

.checkout--subtotal-price .checkout--promocode-total .checkout--promocode-name {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
}

.time-order-modal-wrapper {
    max-width: 700px;
    padding: 50px;
    overflow-y: auto;
}
.time-order-modal-wrapper h2 {
    margin-top: 0;
    margin-bottom: 30px;
}
.time-order-modal-wrapper .btn--action {
    white-space: nowrap;
}

.checkout--total-panel {
    box-shadow: var(--box-shadow);
    padding: 25px;
    border-radius: 10px;
    position: relative;
    background-color: var(--modal-background);
}

@media (min-width: 899px) {
    .checkout--total-wrapper {
        position: sticky;
        top: 0;
        height: fit-content;
    }
}
.checkout--total-panel--title {
    display: flex;
    justify-content: start;
}
.checkout--total-panel--separator {
    border: 1px solid var(--divider-color-contrast);
    border-bottom: 0;
    margin: 20px 0;
}
.checkout--product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.checkout--product .product--info {
    position: relative;
}
.checkout--product-result {
    font-weight: bold;
    white-space: nowrap;
}
.checkout--total-panel--result {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}
.checkout--total-panel--result .money {
    color: var(--main-color);
}

.checkout--total-panel--result .result-delivery,
.checkout--total-panel--result .result-total {
    display: flex;
    justify-content: space-between;
}
.checkout-result-total-divider:first-child {
    display: none;
}
.checkout--user-bonuses {
    margin-top: 0.5rem;
}
.checkout--user-bonuses-info {
    margin-bottom: 0.5rem;
}
.checkout--accrue-bonuses-info {
    display: flex;
    white-space: pre-wrap;
    align-items: center;
}

.checkout--bonuses-payming {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.checkout--payming-bonuses-info {
    display: flex;
    align-items: center;
}
.bonuses-price {
    font-weight: bold;
}
@media (max-width: 899px) {
    .time-order-modal-wrapper h2 {
        text-align: center;
    }
    .checkout--self-address-panel .adress-schdedule {
        margin-top: 4px;
    }
    .checkout--delivery
        .checkout--self-address-panel
        .custom-radio:not(:last-child) {
        margin-bottom: 12px;
    }
    .checkout--delivery
        .checkout--address-panel
        .custom-radio:not(:last-child) {
        margin-bottom: 12px;
    }
}

.checkout--promocode {
    margin-top: 15px;
}
.checkout--promocode .promocode--input-wrapper {
    display: flex;
}
.checkout--promocode .promocode--input-wrapper > div {
    width: 100%;
}

.checkout--gateways {
    margin-bottom: 16px;
}

.checkout--gateways-inputs {
    display: flex;
}

.checkout--gateways-input {
    width: 50%;
    margin-left: 32px;
    margin-top: 16px;
}

.checkout--gateways-input:first-child {
    margin-left: 0px;
}

@media (max-width: 899px) {
    .time-order-modal-wrapper h2 {
        text-align: center;
    }
    .checkout--delivery
        .checkout--self-address-panel
        .custom-radio:not(:last-child) {
        margin-bottom: 12px;
    }
    .checkout--delivery
        .checkout--address-panel
        .custom-radio:not(:last-child) {
        margin-bottom: 12px;
    }
    .checkout--gateways-inputs {
        flex-direction: column;
    }
    .checkout--gateways-input {
        width: 100%;
        margin-left: 0;
    }
}
.checkout--gateways-btn-group {
    width: 100%;
}
.checkout--gateways-btn.MuiToggleButton-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 8.5px 26px;
    margin-top: 0px !important;
    margin-bottom: 8px;
    border-radius: 12px !important;
    border: none !important;
    color: var(--text-color);
}

.checkout--gateways-btn.MuiToggleButton-root.Mui-selected {
    background-color: transparent;
    color: var(--text-color);
}

.checkout--gateways-btn.MuiToggleButton-root.Mui-selected
    > .MuiTouchRipple-root {
    border: 2px solid var(--main-color) !important;
}

.checkout--gateways-btn.MuiToggleButton-root > .MuiTouchRipple-root {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.checkout--gateways-btn.MuiToggleButton-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.checkout--gateways-btn.MuiToggleButton-root img {
    height: 100%;
    max-width: 120px;
}

.checkout--gateways-btn .MuiSvgIcon-root {
    height: 1rem;
}

.checkout--gateways-title-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-align: left;
    margin-right: 10px;
    line-height: 1;
}

.checkout--gateways-btn .MuiRadio-root {
    padding: 0;
    margin-right: 4px;
}

.checkout--errors-container {
    margin-top: 40px;
}

.checkout--button-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.checkout--button-container button {
    flex-basis: 50%;
    width: 100%;
}

.checkout--button-container button:first-child {
    margin-right: 16px;
}

@media (max-width: 480px) {
    .checkout--button-container {
        flex-direction: column;
    }
    .checkout--button-container button {
        flex-basis: 100%;
    }
    .checkout--button-container button:first-child {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

.checkout--info-icon {
    width: 0.75em !important;
    height: 0.75em !important;
    margin-top: 2px;
    margin-left: 4px;
    cursor: pointer;
}

.checkout--info-icon:hover {
    color: var(--main-color);
}
