.account-dialog {
    border-radius: 20px;
}

.account-dialog .modal-alert--wrapper {
    border-radius: 20px;
    min-height: 0;
}

.account-dialog .modal-alert--inner {
    min-width: 0;
}
.account-dialog .button-group {
    display: flex;
    justify-content: flex-end;
}

.account-user-bonuses h2 {
    margin-top: 0;
}
.account-user-bonuses > p {
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    margin-bottom: 0;
}
.account--user-order {
    border: 1px solid var(--divider-color);
    font-size: 15px;
    padding: 25px 25px 75px;
    border-radius: 10px;
    transition: 0.5s;
    backface-visibility: hidden;
    height: 100%;
    position: relative;
}

.account--user-order--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}
.account--user-order--time {
    color: #aaa;
    font-size: 13px;
}

.account--user-order--status {
    padding: 8px 12px;
    border-radius: 20px;
    color: #fff;
    background-color: var(--main-color);
}

.account--user-order--delivery {
    margin-top: 15px;
}
.account--user-order--delivery-type {
    color: var(--main-color);
}

.account--user-order--products {
    padding: 10px 0;
    border-top: 1px solid var(--divider-color-contrast);
    border-bottom: 1px solid var(--divider-color-contrast);
    margin: 15px 0 15px 0;
}
.account--user-order--product {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
}
.account--user-order--product-name {
    margin-right: 10px;
}
.account--user-order--product-modificators {
    font-size: 12px;
    line-height: 1;
    color: #aaaaaa;
    display: flex;
    flex-wrap: wrap;
}
.account--user-order--product-modificator {
    white-space: nowrap;
}
.account--user-order--product-price {
    white-space: nowrap;
}
.account--user-order--product-price .default-price {
    text-decoration: line-through;
    margin-right: 5px;
}
.account--user-order--product-price .default-price {
    color: var(--main-color);
}
.account--user-order--promocode {
    display: flex;
    justify-content: space-between;
}
.account--user-order--promocode-discount {
    font-weight: 600;
}
.account--user-order--auto-discount {
    display: flex;
    justify-content: space-between;
}

.account--user-order--auto-discount .auto-discount-name {
    display: flex;
    align-items: center;
}

.account--user-order--auto-discount .auto-discount-name span {
    margin-right: 4px;
    font-weight: 700;
}

.account--user-order--auto-discount .auto-discount-name svg {
    width: 0.65em;
    height: 0.65em;
    cursor: pointer;
}

.account--user-order--auto-discount .auto-discount-name svg:hover {
    color: var(--main-color);
}
.account--user-order--subtotal {
    display: flex;
    justify-content: space-between;
}
.account--user-order .repeat-order {
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
    text-transform: none;
}
.account--user-order .repeat-order .MuiLoadingButton-loadingIndicator {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.account--user-order--product-toggle-more {
    text-align: right;
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
}

.account--user-order--total-delivery,
.account--user-order--total-amount {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.user-order-skeleton {
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    border-radius: 10px;
}
.deleting-account {
    margin-top: 15px;
    font-size: 14px;
}

.deleting-account .btn--delete-account {
    padding: 0;
    cursor: pointer;
    border: none;
    background: none;
    color: var(--main-color);
    font-size: inherit;
}

.account-separator {
    margin-top: 30px;
    border: 0;
    border-top: 1px solid var(--divider-color-contrast);
}

.btn--auth {
    display: inline;
    padding: 0;
    cursor: pointer;
    border: none;
    background: none;
    color: var(--main-color);
    font-size: inherit;
}

@media (max-width: 320px) {
    .button-group {
        display: flex;
        flex-direction: column;
    }
    .button-group a {
        text-align: center;
    }
    .button-group a,
    .button-group button {
        margin-left: 0;
        margin-right: 0;
    }
    .button-group a:not(:first-child) {
        margin-top: 10px;
    }

    .button-group button:not(:first-child) {
        margin-top: 10px;
    }
}

.account--tab-list {
    overflow: visible !important;
}

.account--tab-list .MuiTabs-scroller {
    overflow: visible !important;
}

.account--tab-list .MuiTabs-indicator {
    display: none;
}

.account--tab-list .Mui-selected {
    border-radius: 8px 8px 0 0;
    background-color: var(--modal-background);
    box-shadow: var(--box-shadow);
    clip-path: inset(-50px -56px 0px -73px);
}

@media (max-width: 500px) {
    .account--tab-list .MuiTabs-scroller {
        overflow: auto !important;
        margin-right: 10px;
        margin-top: -20px;
        padding-top: 20px;
    }
}

.user-bonuses-history {
    border-radius: 10px;
    border: 2px solid var(--divider-color);
    margin-bottom: 24px;
}

.user-bonuses-history--table-container {
    position: relative;
}

.user-bonuses-history--table-container .operation-amount.add {
    white-space: nowrap;
    color: #2e7d32;
}

.user-bonuses-history--table-container .operation-amount.decrease {
    white-space: nowrap;
    color: #ef5350;
}

.mobile-table--operation {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.mobile-table--operation {
    border-bottom: 1px solid #f1f1f1;
}

.mobile-table--operation-left-col,
.mobile-table--operation-right-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile-table--operation-left-col {
    align-items: flex-start;
    margin-right: 8px;
}
.mobile-table--operation-right-col {
    align-items: flex-end;
}
.mobile-table--operation-text {
    margin-bottom: 8px;
    font-weight: 600;
}
.mobile-table--operation-date {
    color: #aaa;
}
.mobile-table--operation-amount {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
    white-space: nowrap;
}
.mobile-table--operation-amount.add {
    color: #2e7d32;
}
.mobile-table--operation-amount.decrease {
    color: #ef5350;
}

.user-bonuses-history--empty-table {
    padding: 20px;
    width: 100%;
    text-align: center;
    color: #aaa;
    border-bottom: 1px solid var(--divider-color);
}
