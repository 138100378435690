.error-page__wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-page__image {
    max-width: 250px;
}

.error-page__refresh-btn {
    border-radius: 20px !important;
    text-transform: none !important;
    border: 1px solid #000 !important;
    background: #fff !important;
    color: #000 !important;
    font-size: 16px !important;
    box-shadow: none !important;
}
.error-page__refresh-btn:hover {
    background: #000 !important;
    color: white !important;
}
