.choose-town-page {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
}

.choose-town-page--logo-container {
    margin-top: 25px;
    max-width: 100vw;
}

.choose-town-page--logo-container img {
    max-width: 800px;
    width: 100%;
    height: auto;
}

.choose-town-page--page-backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.choose-town-page--block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 700px;
    padding: 40px;
    background: var(--background-color);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
        rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
        rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

.choose-town-page--block .block--image-container {
    margin-bottom: 40px;
}

.choose-town-page--block .block--image-container img {
    width: 100%;
    max-width: 350px;
    height: auto;
}

.choose-town-page--block .modal-alert--wrapper {
    min-height: auto;
}

.choose-town-page--block .choose-town--logo {
    width: 100%;
    max-width: 350px;
    margin-bottom: 40px;
    height: auto;
}

@media (max-width: 991px) {
    .choose-town-page--block {
        top: 0;
        left: 0;
        max-width: none;
        max-height: none;
        transform: none;
        box-shadow: none;
        height: 100%;
        border-radius: 0px;
        padding: 25px;
    }

    .choose-town-page--block .modal-alert--inner {
        min-width: unset;
    }
}
