.hard-hours-modal {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 600px;
}

.hard-hours-modal .cat-sleep-img {
    max-width: 200px;
}

@media (max-width: 899px) {
    .hard-hours-modal {
        border-radius: 0px;
        height: 100%;
        min-width: unset;
    }
    .hard-hours-modal .modal-close:not(.vk) {
        right: 15px;
        left: unset;
    }
}
