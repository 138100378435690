.modal-alert--wrapper.choose-town {
    border-radius: 20px;
}

.choose-town--backdrop {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;
    top: 0;
    left: 0;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.choose-town--backdrop.visible {
    visibility: visible;
    opacity: 1;
}

.town-group {
    position: relative;
    margin-bottom: 10px;
}

.town-letter {
    display: block;
    overflow-y: auto;
    border-bottom: 0;
    text-decoration: none;
    font-size: 20px;
    position: absolute;
    left: 0;
}

.town-link {
    font-size: 20px;
    border-bottom: 0;
    text-decoration: none;
    color: var(--text-color);
    font-weight: 100;
    margin-left: 30px;
    display: block;
    width: fit-content;
    cursor: pointer;
    border-bottom: 1px dashed transparent;
}
.town-link:last-child {
    margin-bottom: 0;
}
.town-link:hover {
    border-bottom: 1px dashed var(--text-color, #000);
}
.choosenTown {
    color: var(--main-color);
}
.choose-town--logo {
    width: auto;
    height: 50px;
    margin-bottom: 16px;
}
.mobile-menu--choose-town {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
}
.mobile-menu--choose-town svg {
    margin-right: 10px;
}
.mobile-menu.white .mobile-menu--choose-town svg {
    color: #666;
}

@media (max-width: 899px) {
    .modal-alert--wrapper.choose-town {
        border-radius: 0;
        min-height: 100%;
    }
    .choose-town--backdrop {
        border-radius: 0;
    }
}
