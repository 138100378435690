.sales-wrapper .sale {
    height: 100%;
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 12%) 0 0 20px 5px;
    display: flex;
    flex-direction: column;
}
.sale--short-content {
    height: 100px;
    overflow: hidden;
    z-index: 0;
    flex-grow: 1;
}

.sale--content-inner-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sale--fade {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: -1px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: -moz-linear-gradient(
        top,
        rgba(var(--rgb-modal-background), 0) 0%,
        rgba(var(--rgb-modal-background), 1) 60%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(var(--rgb-modal-background), 0) 0%,
        rgba(var(--rgb-modal-background), 1) 60%
    );
    background: linear-gradient(
        to bottom,
        rgba(var(--rgb-modal-background), 0) 0%,
        rgba(var(--rgb-modal-background), 1) 60%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}

.sale--fade span {
    color: var(--main-color);
    font-size: 13px;
    border-bottom: 1px dashed var(--main-color);
}

.sale-modal--container {
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}

.sale-modal {
    padding: 2rem 2rem 1rem;
    position: relative;
    max-width: 640px;
    overflow-y: auto;
}

@media (max-width: 899px) {
    .sale-modal--container {
        border-radius: 0;
    }
    .sale-modal {
        padding: 80px 20px 20px;
    }
}

.sale-modal::-webkit-scrollbar {
    width: 6px;
}
.sale-modal::-webkit-scrollbar-track {
    background: transparent;
}
.sale-modal::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.modal-close svg {
    width: 1.5em;
    height: 1.5em;
}
.sale-modal .sale-modal--title {
    margin: 0 0 20px 0;
    line-height: 1;
}
@media (max-width: 991px) {
    .sale-dialog .modal-close {
        top: 24px;
        z-index: 2;
        background: var(--background) !important;
    }
    .sale-modal .sale-modal--title {
        margin: 0 30px 20px 0;
        line-height: 1;
    }
}
.sale--img {
    width: calc(100% + 64px);
    margin: -32px -32px 25px -32px;
    max-width: none;
}

.sale--empty-list-placeholder {
    padding: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 20px;
    font-size: 15px;
    justify-content: center;
    text-align: center;
}
