.standart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 0;
}
.header-phone {
    min-width: 0;
}
.header-work,
.header-phone {
    color: var(--text-color);
    display: flex;
    margin-right: 1.5rem !important;
}

.header-phone--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
}

.header-work .icn,
.header-phone .icn {
    width: 28px;
    fill: var(--main-color);
    margin-right: 1rem !important;
    flex-shrink: 0;
}
.header-phone .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-phone .title,
.header-work .title {
    color: #666;
}
.header-phone .info,
.header-phone .town,
.header-work .info {
    font-weight: bold;
    font-size: 19px;
}
.header-phone .info,
.header-phone .town {
    width: fit-content;
    color: var(--text-color);
    text-decoration: none;
    white-space: nowrap;
}

.header-phone .town.link {
    cursor: pointer;
    transition: color 0.2s;
}

.header-phone .info:hover {
    text-decoration: underline;
}
.header-phone .town.link:hover {
    color: var(--main-color);
}
@media (max-width: 991px) {
    .header-work {
        display: none;
    }
    .header--right-col {
        flex-grow: 0 !important;
    }
}
@media (max-width: 899px) {
    .header-phone,
    .header--right-col {
        display: none !important;
    }
}

.header-logo-wrapper {
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-logo {
    width: auto;
    height: 50px;
    max-width: none;
    margin-right: 1.5rem !important;
}

.header-logo-wrapper .header-logo-link {
    display: inline-block;
    height: 50px;
}

#root .header-bar {
    background: var(--header-background);
    box-shadow: none;
    position: relative;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--header-border);
}
.header-login {
    margin-left: 15px;
}
.header--right-col {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header--right-col:empty {
    min-width: 190px;
}

.header--icon-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 14px;
    color: var(--text-color);
    cursor: pointer;
    transition: color 150ms ease 0s;
    position: relative;
}
.header--icon-btn:hover {
    color: var(--main-color);
}
.header--icon-btn:hover .icon-btn--icon {
    transform: translateY(-4px);
}
.icon-btn--icon.MuiSvgIcon-root {
    font-size: 26px !important;
    transition: transform 0.15s ease 0s;
}
.header--bonuses-title {
    font-size: 16px;
    line-height: 1;
}
.header--bonuses-amount {
    position: absolute;
    top: -6px;
    left: calc(50% + 4px);
    background-color: var(--main-color);
    border-radius: 10px;
    font-size: 11px;
    line-height: 16px;
    padding: 0 6px;
    font-weight: 600;
    color: #fff !important;
}

.header-phone .title .MuiSkeleton-text {
    display: inline-block !important;
    min-width: 50px !important;
    vertical-align: middle;
}
.header-work .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-work .info-wrapper .info {
    white-space: nowrap;
}
.header-mobile-menu {
    display: none;
}

/* Второй вариант шапки */

.header-wrapper.one-type .header-work {
    display: none;
}
.header-wrapper.one-type .header-phone {
    order: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.header-wrapper.one-type .header-logo-wrapper {
    order: 1;
}
.header-wrapper.one-type .header-logo {
    margin-right: 0 !important;
}
.header-wrapper.one-type .header--right-col {
    flex-grow: 0;
    order: 3;
}

/* Третий вариант шапки */
.header-wrapper.two-type .header-phone {
    display: none;
}
.header-wrapper.two-type .header-work {
    order: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.header-wrapper.two-type .header-logo-wrapper {
    order: 1;
}
.header-wrapper.two-type .header-logo {
    margin-right: 0 !important;
}
.header-wrapper.two-type .header--right-col {
    flex-grow: 0;
    order: 3;
}

/* Верхнее меню */
.header-bar .MuiToolbar-root {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.top-menu {
    padding-bottom: 16px;
    background: var(--top-menu-background);
    border-bottom: var(--top-menu-border);
}

@media (max-width: 899px) {
    .top-menu {
        display: none;
    }
}

.top-menu ul {
    margin: 0;
    padding: 0 25px 0 0px;
    list-style: none;
}
.top-menu ul li {
    display: inline-block;
    padding: 0;
    margin: 0 22px 0 0;
}
.top-menu ul li a.active {
    color: var(--main-color);
}
.top-menu ul li a:hover {
    color: var(--main-color);
}
.top-menu ul li a {
    display: block;
    line-height: 1;
    color: #666;
    font-size: 16px;
    text-decoration: none;
    padding-bottom: 2px;
}
.top-menu .MuiSkeleton-text {
    margin: 20px 0 15px 0;
    height: 19px;
    display: block;
}

/* Шаги заказа */
.stepper-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.MuiStepper-root {
    min-width: 470px !important;
}
.step-label svg.Mui-active,
.step-label .Mui-completed {
    color: var(--main-color) !important;
}
.step-label .MuiStepLabel-label {
    margin-top: 2px !important;
}

/* Мобильная шапка */
.mobile-menu .MuiDrawer-paper {
    background: black;
    color: white;
}
.mobile-menu.white .MuiDrawer-paper {
    background: white;
    color: black;
}

.mobile-menu-wrapper {
    width: 350px;
}
.mobile-menu-wrapper ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;
    background: black;
    padding: 10px 20px;
}
.mobile-menu.white .mobile-menu-wrapper ul {
    background: white;
}

.mobile-menu--user-account-button {
    padding: 20px 20px 10px 20px;
}
.mobile-menu-wrapper li:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.mobile-menu-wrapper li a {
    font-size: 22px;
    color: #fff;
    padding-left: 3px;
    text-decoration: none;
}

.mobile-menu-wrapper li a.active {
    color: var(--main-color);
}

.mobile-menu.white .mobile-menu-wrapper li a {
    color: black;
}

.mobile-menu.white .mobile-menu-wrapper li a.active {
    color: var(--main-color);
}

.mobile-menu-wrapper li a:hover {
    color: var(--main-color);
}
.mobile-menu-wrapper {
    padding: 20px;
}
.mobile-menu--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.mobile-menu-wrapper .header-work,
.mobile-menu-wrapper .header-phone {
    color: #fff;
    display: flex;
    align-items: center;
    margin: 0 0 20px !important;
    padding: 10px 20px;
}
.mobile-menu.white .header-work,
.mobile-menu.white .header-phone {
    color: black;
}

.mobile-menu--contacts {
    display: flex;
    align-items: center;
    color: white;
    padding: 10px 20px;
}

.mobile-menu--contacts svg {
    width: 28px;
    fill: var(--main-color);
    margin-right: 1rem !important;
}

.mobile-menu--contacts a {
    text-decoration: none;
    font-size: 20px;
    color: white;
    font-weight: bold;
}
.mobile-menu.white .mobile-menu--contacts a {
    color: black;
    font-weight: bold;
}

.mobile-menu--mobile-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    margin: 0 0 20px 10px;
    max-width: 165px;
    border: 2px solid white;
    border-radius: 7px;
}

.mobile-menu--mobile-app a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

.mobile-menu--mobile-app img {
    width: 100%;
}

.modal-alert--wrapper.choose-town .modal-alert--inner button {
    width: 100%;
    padding: 0px !important;
}
.button--choose-town.mobile {
    display: none;
}
@media (max-width: 899px) {
    .standart-header {
        justify-content: flex-end;
    }
    .header-wrapper .standart-header.vk {
        justify-content: center;
    }
    .header-mobile-menu {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .header-mobile-menu svg {
        width: 2rem;
        height: 2rem;
        color: var(--text-color);
    }
    .header-wrapper {
        display: block !important;
        width: 100%;
        text-align: right;
    }
    .mobile-menu-wrapper .header-logo,
    .header-wrapper .header-logo {
        height: 100%;
        width: auto;
        max-height: 50px;
        margin-right: 0 !important;
    }
    .header-logo-wrapper.vk {
        align-items: center;
    }
    .modal-alert--wrapper.choose-town .modal-alert--inner {
        min-width: 200px;
    }
    .modal-alert--wrapper.choose-town .modal-alert--title {
        text-align: center;
    }
    .button--choose-town.mobile {
        display: block;
        top: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .header-stepper {
        display: none !important;
    }
    .stepper-header {
        justify-content: flex-end;
    }
}
