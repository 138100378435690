@font-face {
    font-family: "PT Sans";
    src: url("PTSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "PT Sans";
    src: url("PTSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
