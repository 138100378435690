.bonus-page h1 {
    margin-top: 0;
    line-height: 1;
}

.bonus-page--accrue-info-main {
    margin-bottom: 24px;
}

.user-bonuses-info {
    padding: 25px;
    margin-bottom: 24px;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    background-color: var(--modal-background);
}

.user-bonuses-info.solid {
    border: 2px solid var(--divider-color);
    box-shadow: none;
}

.user-bonuses-info--exchange {
    font-size: 24px;
    margin-top: 10px;
}

.user-bonuses-info--user-bonuses-title {
    margin-top: 10px;
}

.user-bonuses-info--user-bonuses {
    font-size: 24px;
    margin-top: 10px;
}

.user-bonuses-info--user-bonuses-expire {
    margin-top: 10px;
}
.user-bonuses-info--user-bonuses-expire-date {
    font-weight: 700;
}

.bonus-page--additional-info-container {
    max-width: 980px;
    margin-bottom: 24px;
}

.bonus-page--additional-info-block {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 25px;
    border-radius: 10px;
    background: var(--main-color);
}

.bonus-page--additional-info-block * {
    color: white;
}

.bonus-page--accrue-info {
    margin-bottom: 24px;
}
.bonus-page--accrue-info div,
.bonus-page--payment-info div {
    margin-top: 8px;
}

.bonus-page--accrue-info ul,
.bonus-page--payment-info ul {
    margin: 4px 0 0 0;
}

.bonus-page--accrue-info h3,
.bonus-page--payment-info h3 {
    margin: 0;
}
