.product {
    height: 100%;
    border-radius: 15px;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.product-grid-item.one-layout .product {
    box-shadow: var(--product-box-shadow);
}

.product.disabled {
    opacity: 0.5;
}

.product:hover {
    box-shadow: var(--product-box-shadow);
    z-index: 1;
    overflow: hidden;
    border-radius: 15px;
    transition: box-shadow 0.15s;
}
.product-grid-item.one-layout .product:hover {
    box-shadow: var(--product-hover-box-shadow);
}

.product--image {
    height: 180px;
    box-sizing: initial;
    padding: 20px 20px 0 20px;
}

.product--image .lazyload-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product--image.fixedRatio .lazyload-wrapper {
    padding-top: 68.75%;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}
.one-layout .product--image.fixedRatio .lazyload-wrapper {
    border-radius: 0;
}

.product--image img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
}

.product--image img.soon {
    height: 100%;
}

@media (min-width: 899px) {
    .product--image.fullwidth {
        height: auto;
    }
    .one-layout .product .product--image {
        position: relative;
        padding: 0 0 75% 0;
        height: auto;
    }

    .one-layout .product .product--image img {
        border-radius: 0;
        position: absolute;
        top: 0;
    }

    .one-layout .product .product--image.fullwidth {
        padding: 0;
    }
    .one-layout .product .product--image.fullwidth img {
        position: relative;
        width: 100%;
    }
    .product .product--image.fullwidth.fixedRatio img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: none;
    }
}

.product--image .placeholder img {
    width: 100%;
    left: 0;
}
.product--image img,
.product.open .product--image img {
    transition: transform 0.15s;
}
.product--inner-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    flex-grow: 1;
}
.product--title {
    position: relative;
    margin-top: 10px;
    margin-bottom: 0.75rem;
    font-size: 20px;
    font-weight: bolder;
    text-overflow: ellipsis;
    flex-grow: 1;
}
.product--title > * {
    margin: 0;
}
.product--description {
    position: relative;
    height: 48px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.15;
    color: #666;
    word-break: break-word;
    margin-bottom: 8px;
}
.short-fade {
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 22px;
    background: -moz-linear-gradient(
        top,
        rgba(var(--product-fade-color), 0) 0%,
        rgba(var(--product-fade-color), 1) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(var(--product-fade-color), 0) 0%,
        rgba(var(--product-fade-color), 1) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(var(--product-fade-color), 0) 0%,
        rgba(var(--product-fade-color), 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}
.product--inner-wrapper .action {
    width: 100%;
    text-align: end;
    cursor: pointer;
    color: var(--main-color);
    font-size: 13px;
}
.product--inner-wrapper .action span {
    border-bottom: 1px dashed var(--main-color);
}
.product .product--image:hover,
.product .product--title:hover,
.product .product--description:hover {
    cursor: pointer;
}

.product--buying {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: flex-end;
}

.product--buying .btn-buy {
    height: 40px;
    white-space: nowrap;
}

.product--price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product--price {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
}
.product--old-price {
    position: relative;
    margin-right: 5px;
    font-size: 14px;
}

.product--sales {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.product--info {
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
}
.product--info .weight {
    position: relative;
    font-size: 14px;
    color: #aaa !important;
    margin-right: 10px;
}
.product--info .count-rolls {
    position: relative;
    font-size: 14px;
    color: #aaa !important;
}
.product--quantity {
    display: flex;
    font-size: 0;
    bottom: 0px;
}

.product--quantity .product-decrease,
.product--quantity .product-add {
    width: 32px !important;
    border-radius: 50% 0 0 50%;
    padding: 0;
    cursor: pointer;
    border: 0;
    color: #999;
    background: var(--counter-buttons-color);
    font-size: 16px;
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
}
.product--quantity .product-add {
    border-radius: 0 50% 50% 0;
}
.product--quantity .product-decrease {
    border-radius: 50% 0 0 50%;
}
.product--quantity .product-decrease:hover,
.product--quantity .product-add:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: white;
}
.change-quanity.modal-change-quantity .product-decrease:hover,
.change-quanity.modal-change-quantity .product-add:hover {
    background: var(--sec-color);
    border-color: var(--sec-color);
}
.product--quantity .quantity {
    height: 40px;
    width: 34px;
    display: inline-block;
    border: 0;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 500;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 0;
    vertical-align: middle;
    background: var(--counter-buttons-color);
    outline: none !important;
}
.product--labels-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 13px;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 899px) {
    .product {
        background: var(--product-background);
    }
    .product::before {
        box-shadow: inset 0 0 0 1px var(--product-border);
        height: 100%;
        width: 100%;
        position: absolute;
        content: "";
        border-radius: inherit;
        pointer-events: none;
    }

    .product-grid-item:has(.product--image.fullwidth) .product--labels-wrapper {
        top: 30px;
        left: 30px;
    }

    .product-grid-item.one-layout:has(.product--image.fullwidth)
        .product--labels-wrapper {
        top: 20px;
        left: 20px;
    }
}

.product--label.peoples svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    position: relative;
}
.product--label {
    background: #f3f3f7;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: 0 5px 5px 0;
    display: inline-flex;
    align-items: center;
}
.product--label.hit {
    color: #000;
    background: #fbcd00;
}
.product--label.peoples {
    color: #000;
}
.product--label.new,
.product--label.discount {
    background: #ea1b14;
    color: white;
}

@media (max-width: 899px) {
    .product-grid-item {
        border-radius: 0;
        width: 100%;
    }
    .product-grid-item.default-layout {
        padding: 0 0 25px;
    }
    .product-grid-item.default-layout:not(:last-child) {
        margin-bottom: 20px !important;
        border-bottom: 1px solid var(--divider-color);
    }
    .product-grid-item.default-layout .product {
        padding: 0;
        max-width: 100%;
        height: auto;
        box-shadow: none;
        border-radius: 0;
    }
    .product-grid-item.default-layout .product {
        flex-direction: row;
    }
    .product-grid-item.one-layout .product {
        border-radius: 15px;
        background: var(--product-background);
    }
    .product-grid-item.one-layout .product::before {
        box-shadow: inset 0 0 0 1px var(--product-border);
        height: 100%;
        width: 100%;
        position: absolute;
        content: "";
        border-radius: inherit;
        pointer-events: none;
    }
    .product--image .placeholder img {
        width: 100%;
        left: 0;
    }
    .product .product--image {
        min-width: 130px !important;
        margin-right: 15px;
        width: 130px;
        padding: 0;
        height: auto;
    }

    .product-grid-item.one-layout .product .product--image {
        min-width: unset !important;
        margin-right: 0;
        width: 100%;
        padding: 0;
        height: auto;
        position: relative;
        padding: 0 0 75% 0;
    }

    .product .product--image img {
        border-radius: 10px;
        position: initial;
        max-width: 100%;
        max-height: 100%;
        bottom: auto;
        left: 0;
        transform: translate(0);
    }
    .product-grid-item.one-layout .product .product--image .lazyload-wrapper {
        display: block;
    }

    .product-grid-item.one-layout .product .product--image img {
        border-radius: 0;
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
    }

    .one-layout .product .product--image.fullwidth {
        padding: 0;
    }
    .one-layout .product .product--image.fullwidth img {
        position: relative;
        width: 100%;
    }
    .product .product--image.fullwidth.fixedRatio img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        max-height: none;
    }

    .product-grid-item.one-layout .product .product--image .placeholder img {
        left: 0;
    }

    .product--labels-wrapper {
        display: block;
        width: 150px;
        top: 0;
        left: 0;
    }
    .product-grid-item.one-layout .product--labels-wrapper {
        top: 20px;
        left: 20px;
        width: auto;
    }
    .product--label {
        display: inline-block;
    }
    .product--title {
        white-space: normal;
        margin-top: 0;
    }
    .product-grid-item.one-layout .product--title {
        margin-top: 10px;
    }
    .product--inner-wrapper {
        width: 100%;
        padding: 0;
    }
    .product-grid-item.one-layout .product--inner-wrapper {
        padding: 0 20px 20px 20px;
    }
    .product:hover {
        box-shadow: none;
    }

    .product--quantity .product-decrease,
    .product--quantity .product-add {
        width: 28px !important;
    }

    .cart--product .product-decrease,
    .cart--product .product-add {
        height: 28px !important;
    }
    .product--quantity .quantity {
        width: 28px;
        display: inline-block;
    }
    .cart--product .product--quantity .quantity {
        height: 28px;
    }
    .product-grid-item:not(.one-layout) .short-fade {
        background: -moz-linear-gradient(
            top,
            rgba(var(--rgb-background-color), 0) 0%,
            rgba(var(--rgb-background-color), 1) 100%
        );
        background: -webkit-linear-gradient(
            top,
            rgba(var(--rgb-background-color), 0) 0%,
            rgba(var(--rgb-background-color), 1) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(var(--rgb-background-color), 0) 0%,
            rgba(var(--rgb-background-color), 1) 100%
        );
    }
}

@media (max-width: 600px) {
    .product-grid-item.one-layout {
        width: 100%;
        margin: 0 0 20px 0;
        margin-bottom: 20px !important;
    }
}
