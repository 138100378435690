.cart--bonuses-products .bonus-product {
    width: 100%;
    display: flex;
}
.bonus-product-choose {
    display: flex;
    align-items: center;
}
.bonus-product-choose:hover {
    cursor: pointer;
}
.bonus-product-choose[disabled] {
    opacity: 0.5;
    filter: grayscale(1);
}

.bonus-product-choose-content {
    display: flex;
    width: 100%;
}

.cart--bonuses-products .bonus-product .bonus-product--image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px;
    width: 70px;
    margin-right: 15px;
}

.cart--bonuses-products .bonus-product img {
    width: 70px;
    max-width: initial;
    border-radius: 5px;
}

.cart-product-list .cart--bonuses-products .cart--product-remove svg {
    width: 1.2rem;
    height: 1.2rem;
}

.minicart--product-list .cart--bonuses-products .cart--product-remove svg {
    width: 15px;
    height: auto;
}

.bonus-product--info {
    display: flex;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
    font-size: 12px;
}
.bonus-product--info .weight {
    position: relative;
    color: #aaa !important;
    margin-right: 10px;
}
.bonus-product--info .count-rolls {
    position: relative;
    color: #aaa !important;
}
