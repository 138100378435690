.order-complete-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-complete--logo-container {
    max-width: 250px;
}

.order-complete--logo {
    width: 100%;
}

.order-complete-page--content-container {
    text-align: center;
}
