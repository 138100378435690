@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Fira Sans";
    src: url("FiraSans-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
