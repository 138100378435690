.sticked-top-bar {
    margin-bottom: 16px;
    background-color: #000;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 15px 0;
    transition: background-color 0.5s ease-in-out;
}
.sticked-top-bar.white {
    background-color: rgba(var(--sticked-bar-background), 0);
    transition: background-color 0.1s;
}
.sticked-top-bar.white.sticked {
    transition: background-color 0.1s;
}

.sticked-top-bar.filled {
    background-color: var(--main-color);
}

.sticked-top-bar.sticked {
    background-color: rgba(
        var(--sticked-bar-background),
        var(--sticked-bar-opacity)
    );
    backdrop-filter: blur(20px);
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 10%);
    top: -1px;
    transition: background-color 0.5s ease-in-out;
}

.sticked-top-bar.sticked.filled {
    background-color: var(--main-color);
}

@media (min-width: 900px) {
    .sticked-top-bar.fullWidthSite {
        top: 62px;
    }
}

.sticked-top-bar ul.categories-menu {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0;
    margin-right: 20px;
    list-style: none;
    font-size: 0;
    padding: 0;
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
}
.sticked-top-bar ul.categories-menu .scroll-container {
    padding: 20px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.sticked-top-bar ul.categories-menu .scroll-container::-webkit-scrollbar {
    height: 7px;
}
.sticked-top-bar ul.categories-menu .scroll-container::-webkit-scrollbar-track {
    background: transparent;
}
.sticked-top-bar ul.categories-menu .scroll-container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.sticked-top-bar .inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sticked-top-bar ul.categories-menu li {
    display: flex;
    flex: 0 0 auto;
    font-size: 16px;
}
.sticked-top-bar ul.categories-menu li.visible {
    order: 0;
    visibility: visible;
    opacity: 1;
}

.sticked-top-bar ul.categories-menu li.invisible {
    order: 100;
    visibility: hidden;
    pointer-events: none;
}

.sticked-top-bar ul.categories-menu ul.flexmenu--dropdown li {
    display: block;
}
.sticked-top-bar ul.categories-menu li a {
    width: fit-content;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.sticked-top-bar.white ul.categories-menu li a {
    color: var(--text-color);
}

.sticked-top-bar.filled ul.categories-menu li a {
    color: #fff;
}

.sticked-top-bar ul.categories-menu li a:hover {
    text-decoration: none;
    border-bottom: 1px dashed;
}
.sticked-top-bar ul.categories-menu li a.active {
    color: var(--main-color) !important;
}
.sticked-top-bar.filled ul.categories-menu li a.active {
    color: #fff !important;
    border-bottom: 1px dashed;
}

.sticked-top-bar.sticked ul.categories-menu li a {
    color: var(--text-color);
}
.sticked-top-bar.sticked.filled ul.categories-menu > li a {
    color: #fff;
}
@media (max-width: 899px) {
    .sticked-top-bar--container {
        height: 56px;
        width: 100%;
        margin-bottom: 16px;
    }
    .sticked-top-bar {
        width: 100%;
        background: var(--background-color);
        padding: 0;
    }

    .sticked-top-bar.sticked {
        background-color: var(--background-color);
        backdrop-filter: none;
        position: fixed;
        margin-bottom: 16px;
    }
    .sticked-top-bar ul.categories-menu {
        overflow: visible;
        overflow-y: auto;
        white-space: nowrap;
        margin: 0 -16px;
        padding: 8px 16px;
    }
    .sticked-top-bar ul.categories-menu li a {
        font-size: 14px;
        padding: 0 16px;
        margin: 4px 0;
        background: var(--counter-buttons-color);
        color: var(--text-color);
        border: 0 !important;
        border-radius: 15px;
        display: inline-block;
        height: 32px;
        line-height: 32px;
        margin-right: 15px;
        z-index: 11;
    }
    .sticked-top-bar.filled ul.categories-menu li a {
        background-color: var(--background-color);
        color: var(--main-color);
    }
    .sticked-top-bar.sticked.filled ul.categories-menu li a {
        background-color: var(--background-color);
        color: var(--main-color);
    }
    .sticked-top-bar.white.sticked {
        background-color: rgba(var(--sticked-bar-background), 1);
    }

    .sticked-top-bar ul.categories-menu li:last-child a {
        margin-right: 0px;
    }
    .sticked-top-bar ul.categories-menu li a:hover {
        text-decoration: none;
    }

    .sticked-top-bar ul.categories-menu li a.active {
        background-color: var(--main-color);
        color: #fff !important;
    }
    .sticked-top-bar.filled ul.categories-menu li a.active {
        background-color: var(--sec-color);
        color: #fff !important;
    }

    /* Кнпока меню в списке категорий */
    .sticked-top-bar ul.categories-menu li a.menu-icon {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-right: -44px;
        transform: translateX(-60px);
        will-change: margin-right, transform;
        transition-property: margin-right, transform;
        transition-duration: 150ms;
        transition-timing-function: ease-out;
    }
    .sticked-top-bar.sticked ul.categories-menu li a.menu-icon {
        margin-right: 15px;
        transform: translateX(0px);
    }
    .modal-close {
        position: absolute !important;
        right: auto;
        left: 17px;
        top: 7px;
        color: black !important;
    }
    .sticked-top-bar ul.categories-menu .scroll-container {
        padding: 10px 0;
    }
}
.sticked-top-bar ul.categories-menu li .categoriesPopupMenu {
    transition: all 0.15s;
    background: #fff;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 23%);
    border-radius: 5px;
    padding: 15px;
    top: 48px;
    right: 0;
    position: absolute;
    width: 200px;
    margin-right: -60px;
}
.sticked-top-bar ul.categories-menu li .categoriesPopupMenu a {
    display: block;
    color: black !important;
}

.flexmenu--more {
    display: flex;
    order: 99;
    position: sticky;
    right: 0;
    font-size: 14px;
    cursor: pointer;
}
.flexmenu--dropdown {
    display: none;
    transition: all 0.15s;
    background: var(--background-color);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 23%);
    border-radius: 5px;
    padding: 15px;
    top: 39px;
    right: 0;
    position: absolute;
}
.flexmenu--more:hover .flexmenu--dropdown {
    display: block;
}

.sticked-top-bar ul.categories-menu:has(.flexmenu--more:hover) {
    margin-bottom: -700px;
    padding-bottom: 700px;
}

.flexmenu--more:hover .btn.btn--action {
    background: var(--second-color);
}
.flexmenu--dropdown:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background: var(--background-color);
    height: 20px;
    width: 20px;
    right: 25px;
    top: -3px;
    content: "";
    display: block;
    position: absolute;
}
.flexmenu--dropdown:before {
    display: block;
    content: " ";
    background: transparent;
    height: 20px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -20px;
}
.sticked-top-bar ul.categories-menu .flexmenu--more .flexmenu--dropdown a {
    display: block;
    margin: 8px;
    color: var(--text-color);
    border-bottom: 1px dashed transparent !important;
    white-space: nowrap;
}
.sticked-top-bar ul.categories-menu .flexmenu--dropdown a:hover {
    border-bottom: 1px dashed var(--text-color) !important;
    background: rgba(var(--main-color), 0.2);
}
