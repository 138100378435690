.minicart--text-info {
    border: 1px solid #99dfff;
}
.minicart.btn--action {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.sticked-top-bar.filled .minicart.btn--action {
    background-color: var(--background-color) !important;
}

.sticked-top-bar.filled .minicart--topcart--total {
    color: var(--main-color);
}
.sticked-top-bar.filled .minicart--cart-icon {
    color: var(--main-color);
}

.sticked-top-bar.filled .minicart.btn--action:hover .minicart--topcart--total {
    color: var(--sec-color);
}
.sticked-top-bar.filled .minicart.btn--action:hover .minicart--cart-icon {
    color: var(--sec-color);
}

.minicart.btn--action:hover {
    background: var(--main-color) !important;
    box-shadow: 0px 0px 15px 0px var(--main-color);
    cursor: pointer;
}

.minicart--separator {
    display: inline-block;
    width: 1px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    height: 15px;
    vertical-align: middle;
    margin: 0 10px;
}
.minicart--topcart--total {
    position: relative;
    top: auto;
    display: inline-flex;
    align-items: center;
    left: auto;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    width: 70px;
}
.minicart--topcart--total .minicart--topcart--price-total {
    position: absolute;
    white-space: nowrap;
    top: 0;
    left: 0;
    transition: all 0.25s;
}
.minicart--topcart--total .minicart--topcart--count-item {
    white-space: nowrap;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translate(-100px, 0);
    transition: all 0.25s;
}
.btn--action.minicart:hover .minicart--topcart--price-total {
    opacity: 0;
}
.btn--action.minicart:hover .minicart--topcart--count-item {
    opacity: 1;
    transform: translate(0, 0);
}

.minicart--empty {
    text-align: center;
    position: absolute;
    left: 15px;
    width: auto;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.minicart--empty svg,
.minicart--empty img {
    width: auto;
    height: 100%;
    max-width: 200px;
    max-height: 200px;
    color: var(--text-color);
}

.minicart--inner {
    display: flex;
    flex-direction: column;
    width: 420px;
    height: 100%;
    background: var(--mini-cart-background);
}
.minicart--product-list {
    height: 100%;
    overflow: auto;
    position: relative;
    padding-bottom: 10px;
}
.minicart--product-list::-webkit-scrollbar {
    width: 6px;
}
.minicart--product-list::-webkit-scrollbar-track {
    background: transparent;
}
.minicart--product-list::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.minicart--inner-title {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.minicart--inner-title.vk {
    flex-direction: row-reverse;
    justify-content: flex-end;
    line-height: 0.8;
}
.minicart--inner-title.vk > *:first-child {
    margin-left: 6px;
}
.minicart--angle-icon {
    height: 0.9em;
    width: 0.9em;
}
.minicart--product {
    margin-bottom: 10px;
    padding: 15px;
    background: var(--background-color);
    position: relative;
    font-size: 14px;
    box-shadow: 0 0 10px 0 rgb(100 100 100 / 10%);
}
.minicart--product.free-addon .minicart--product-quantity .quantity {
    border-radius: 50%;
}
.minicart--product-info {
    display: flex;
}
.minicart--product-attributes {
    font-size: 13px;
    color: #5c6370 !important;
}
.minicart--product-image img {
    width: 60px;
    border-radius: 4px;
}
.minicart--product-inner {
    flex-grow: 1;
    padding-left: 10px;
    position: relative;
}
.minicart--product-info {
    display: flex;
    font-weight: 400;
}
.minicart--product-info .weight {
    position: relative;
    font-size: 13px;
    color: #5c6370 !important;
    margin-right: 10px;
}
.minicart--product-info .count-rolls {
    position: relative;
    font-size: 13px;
    color: #5c6370 !important;
}

.minicart--product-info .promocode-name {
    font-size: 13px;
    color: #5c6370 !important;
}

.minicart--product-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px;
}

.minicart--product-remove {
    display: flex;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.2s;
    margin-left: 8px;
}
.minicart--product-remove:hover {
    color: var(--main-color);
}
.minicart--product-remove svg {
    width: 15px;
    height: auto;
}
.minicart--product-price {
    font-size: 16px;
    margin-top: 5px;
    font-weight: bold;
}
.minicart--product-name {
    font-weight: bold;
    color: var(--text-color);
    line-height: 1;
    font-size: 16px;
    word-break: break-word;
}
.minicart--product-quantity {
    font-size: 0;
    right: 0;
    bottom: 0px;
    display: flex;
    flex-wrap: nowrap;
}
.minicart--product-quantity .product-decrease,
.minicart--product-quantity .product-add {
    height: 32px !important;
    width: 32px !important;
    border-radius: 50% 0 0 50%;
    line-height: 1;
    padding: 6px 16px !important;
    cursor: pointer;
    border: 0;
    color: #999;
    background: var(--counter-buttons-color);
    font-size: 16px;
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
}
.minicart--product-quantity .product-add {
    border-radius: 0 50% 50% 0;
}
.minicart--product-quantity .product-decrease {
    border-radius: 50% 0 0 50%;
}
.minicart--product-quantity .product-decrease:hover,
.minicart--product-quantity .product-add:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: white;
}
.change-quanity.modal-change-quantity .product-decrease:hover,
.change-quanity.modal-change-quantity .product-add:hover {
    background: var(--sec-color);
    border-color: var(--sec-color);
}
.minicart--product-quantity .quantity {
    width: 40px;
    display: inline-block;
    height: 32px;
    border: 0;
    font-size: 14px;
    color: var(--text-color);
    font-weight: 500;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 0;
    vertical-align: middle;
    background: var(--counter-buttons-color);
    outline: none !important;
}
.minicart--product-quantity .minicart--variations-buy {
    color: var(--text-color);
    height: 32px;
    font-size: 14px;
    background: var(--counter-buttons-color);
}
.minicart--product-quantity .minicart--variations-buy:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: white;
}
.minicart--product-result {
    font-weight: bold;
    justify-content: space-between;
    align-items: center;
    display: flex;
    font-size: 16px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--divider-color);
}
.minicart--free-addons-title {
    margin: 40px 0 10px;
    padding-left: 20px;
}

.minicart--total-wrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--background-color);
    padding: 15px;
    z-index: 1;
    box-shadow: rgb(6 5 50 / 20%) 0px 0px 10px;
}
.minicart--total-wrapper .btn--action {
    width: 100%;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 6px 16px;
    border-radius: 4px;
    line-height: 1.8;
    margin-top: 5px;
    font-size: 16px !important;
}

.minicart--total-divider:first-child {
    display: none;
}

.minicart--total-block {
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}
.minicart--total-price {
    color: var(--main-color);
    white-space: nowrap;
}
.mobile-header--contacts {
    padding: 15px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    font-size: 28px;
}
.mobile-header--contacts a {
    text-decoration: none;
}
.minicart-mobile {
    position: relative;
    margin-left: auto;
    bottom: 20px;
    right: 20px;
    background: var(--background-color);
    color: var(--main-color);
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 100;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
}
.active-bonuses .minicart-mobile {
    bottom: 60px;
}
.minicart-mobile svg {
    width: 30px;
}

.minicart-mobile--count-item {
    position: absolute;
    top: -5px;
    right: -5px;
    background: var(--main-color);
    color: white;
    width: 23px;
    height: 23px;
    line-height: 1;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.minicart--subtotal-price > *:first-child {
    margin-top: 0.7rem;
}

.minicart--subtotal-price > *:last-child {
    margin-bottom: 0.7rem;
}

.minicart--subtotal-price .price,
.minicart--subtotal-price .promocode {
    display: flex;
    justify-content: space-between;
}

.mini-cart-bonuses {
    display: flex;
    justify-content: space-between;
}

.mini-cart-bonuses--title {
    display: flex;
    align-items: center;
}

.mini-cart-bonuses--title svg {
    width: 0.75em;
    height: 0.75em;
    margin-top: 4px;
    margin-left: 4px;
    cursor: pointer;
}
.mini-cart-bonuses--title svg:hover {
    color: var(--main-color);
}

.mini-cart-bonuses--tooltip {
    font-size: 13px;
    margin: 4px;
}

.mini-cart-bonuses--tooltip-more {
    margin-top: 8px;
    color: var(--main-color);
    cursor: pointer;
    width: fit-content;
}
@media (min-width: 550px) and (max-width: 899px) {
    .minicart--inner {
        max-width: 450px;
    }
}

@media (max-width: 550px) {
    .minicart--inner {
        max-width: none;
    }
}

@media (max-width: 899px) {
    .mobile-minicart .minicart {
        position: fixed;
        text-decoration: none;
        bottom: 0;
        left: 0;
        right: 0;
        height: 56px;
        background: white;
        box-shadow: 0 -4px 5px 1px rgb(0 0 0 / 10%);
    }
    .minicart-modal--title {
        padding-left: 15px;
    }
    .minicart--separator {
        display: none;
    }
    .minicart--topcart--total {
        display: flex !important;
        width: 100% !important;
        justify-content: space-between;
        padding: 0 16px;
        height: 56px;
        color: var(--main-color);
        left: auto;
        width: 100px;
        display: block;
        text-align: left;
        font-size: 16px;
    }
    .minicart--inner {
        width: 100vw;
        padding: 0;
    }
    .minicart--topcart--total .minicart--topcart--price-total {
        position: relative;
        white-space: nowrap;
        display: block;
    }
    .minicart--product-list {
        height: calc(100%);
        overflow: auto;
    }
    .minicart--topcart--total .minicart--topcart--count-item {
        white-space: nowrap;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        opacity: 1;
        color: black;
        transform: none;
        transition: all 0.25s;
    }
    .btn--action.minicart:hover .minicart--topcart--price-total {
        opacity: 1;
    }
}
