.product-modal .product-page--image {
    padding: 80px 20px 20px 0;
    height: fit-content;
    position: sticky;
    top: 0;
    width: 50%;
}
.product-modal .product-page--image img {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
}

.product-modal .variations-buying {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px 0px 0px;
}

.product-modal .variations-buying .btn-buy {
    flex-basis: 50%;
}

@media (max-width: 899px) {
    .product-modal .product-page--image {
        padding: 0 20px 20px 0;
        position: relative;
        top: auto;
    }
    .product-modal .product-page--image,
    .product-modal--info {
        border-radius: 0;
        width: 100%;
    }
}
