.fullwidth-header--wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    border-bottom: solid 1px var(--divider-color);
    background: var(--header-background);
    margin-bottom: 16px;
}

.fullwidth-header {
    display: flex;
    align-items: center;
    padding: 8px 0;
    position: sticky;
    top: 0;
}

.fullwidth-header--menu-btn {
    background-color: var(--counter-buttons-color);
    height: 42px;
    width: 42px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    transition: all 0.2s;
    cursor: pointer;
}

.fullwidth-header--menu-btn:hover {
    background-color: var(--mobile-menu-btn-hover);
}

.fullwidth-header--logo-container {
    display: flex;
    margin-right: 24px;
}

.fullwidth-header--logo {
    height: 45px;
}

.fullwidth-header--town-info {
    display: flex;
    flex-direction: column;
}

.fullwidth-header--town {
    font-size: 20px;
    color: var(--main-color);
    line-height: 1;
}

.fullwidth-header--time {
    font-size: 14px;
    color: #666;
}

.fullwidth-header--right-col {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 899px) {
    .fullwidth-header--wrapper {
        position: static;
        top: 0;
        z-index: 10;
    }

    .fullwidth-header {
        justify-content: flex-end;
    }

    .fullwidth-header--menu-btn,
    .fullwidth-header--town-info,
    .fullwidth-header--right-col {
        display: none;
    }
}
