.banners-skeleton {
    padding-top: 29%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2rem;
}

.banners-skeleton--skeleton {
    top: 0;
    position: absolute;
    border-radius: 25px;
    width: 100%;
    height: 100% !important;
}

@media (max-width: 899px) {
    .banners-skeleton {
        border-radius: 5px;
        padding-top: 52.75%;
        margin-bottom: 0;
    }
    .banners-skeleton--skeleton {
        border-radius: 5px;
    }
}

.banners-wrapper {
    overflow-x: hidden;
}

.banners-swiper.swiper {
    margin: 0 0 16px 0;
    border-radius: 25px;
    overflow: visible;
}

.banners-swiper .swiper-slide {
    opacity: 0.4;
}

.banners-swiper .swiper-slide.swiper-slide-active {
    opacity: 1;
}

.banners-swiper .banner-slide--image-container {
    padding-top: 29%;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
    width: 100%;
}

.banners-swiper img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 25px;
    width: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.banners-swiper .swiper-pagination {
    bottom: 20px !important;
    text-align: left;
    left: 20px !important;
}
.banners-swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--main-color) !important;
}
.banners-swiper .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
}
.banners-swiper .swiper-button-prev,
.banners-swiper .swiper-button-next {
    color: #fff;
    z-index: 15;
    top: auto;
    bottom: 20px;
    display: none;
}

.banners-swiper .swiper-button-next {
    color: #fff;
    z-index: 15;
    right: 20px;
    left: auto;
    bottom: 20px;
    padding-left: 3px;
}

.banners-swiper .swiper-button-prev {
    padding-right: 3px;
    left: auto;
    right: 65px;
}

.banners-swiper .swiper-container:hover .swiper-button-next,
.banners-swiper .swiper-container:hover .swiper-button-prev {
    display: flex;
}

.banners-swiper .swiper-button-prev:after,
.banners-swiper .swiper-button-next:after {
    font-size: 14px;
}
.banners-swiper .swiper-button-prev,
.banners-swiper .swiper-button-next {
    margin-top: -30px;
    width: 34px;
    height: 34px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    color: white !important;
}
.banners-swiper:hover .swiper-button-prev,
.banners-swiper:hover .swiper-button-next {
    display: flex;
}
.banners-swiper {
    line-height: 1;
}

.bannersMobile-swiper {
    width: 90%;
}

.bannersMobile-swiper .swiper-slide {
    opacity: 0.4;
}

.bannersMobile-swiper .swiper-slide.swiper-slide-active {
    opacity: 1;
}

.bannersMobile-swiper .banner-slide--image-container {
    padding-top: 52.75%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
}

.bannersMobile-swiper img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    border-radius: 5px;
}
.mobile-banners-wrapper {
    padding: 15px 0 40px 0;
    overflow: hidden;
    width: 100%;
}
.mobile-banners-wrapper .swiper {
    overflow: visible !important;
}
.mobile-banners-wrapper .swiper-pagination {
    bottom: -20px !important;
}
.mobile-banners-wrapper .swiper-pagination-bullet-active {
    background: var(--main-color) !important;
}
