.mobile-apps-widget {
    width: 100%;
    overflow: hidden;
    padding: 28px 0 18px 0;
    background-color: var(--main-color);
    position: relative;
}

.mobile-apps-widget--inner-wrapper {
    display: flex;
    justify-content: space-between;
}

.mobile-apps-widget--logo-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 125px;
}

.mobile-apps-widget--logo {
    height: 200px;
    width: 100%;
    position: absolute;
    top: 10px;
}

.mobile-apps-widget--circle {
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 50%;
}

.mobile-apps-widget--inner-circle {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.6);
}

.mobile-apps-widget--img {
    position: absolute;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 45px);
    top: calc(50% + 10px);
    left: 50%;
    transform: translate(-50%, -50%);
}

.mobile-apps-widget--description {
    flex-grow: 1;
    color: #fff;
    margin-left: 224px;
    margin-right: 32px;
    max-width: 450px;
}

.mobile-apps-widget--links-container {
    display: flex;
    flex-wrap: nowrap;
}

.mobile-apps-widget--link:not(:last-child) {
    margin-right: 16px;
}

.mobile-apps-widget--link a {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 1;
}

.mobile-apps-widget--link img {
    max-width: 145px;
    border: 2px solid white;
    border-radius: 7px;
}

.mobile-apps-widget--link:hover img {
    background-color: rgba(255, 255, 255, 0.5);
    transition: background-color 0.2s;
}

@media (max-width: 899px) {
    .mobile-apps-widget--inner-wrapper {
        flex-wrap: wrap-reverse;
        justify-content: space-around;
        row-gap: 32px;
        column-gap: 16px;
    }
    .mobile-apps-widget--logo-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 150px;
        width: 150px;
        order: 1;
    }
    .mobile-apps-widget--logo {
        height: auto;
        top: 0;
    }
    .mobile-apps-widget--img {
        top: 65%;
        max-width: calc(100% - 40px);
    }
    /* .mobile-apps-widget--circle {
        width: 160px;
        height: 160px;
    } */
    .mobile-apps-widget--description {
        margin-right: 0;
        margin-left: 0;
        order: 3;
        text-align: center;
        max-width: none;
    }
    .mobile-apps-widget--links-container {
        flex-direction: column;
        order: 2;
    }
    .mobile-apps-widget--link:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
    }
}
