:root {
    --swiper-navigation-size: 22px;
    color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--text-color);
}

body {
    background-color: var(--body-background);
}
.main-color {
    color: var(--main-color);
}
a {
    color: var(--main-color);
}
a:hover {
    cursor: pointer;
}
* {
    box-sizing: border-box;
}
h1 {
    font-size: 42px;
}
h3 {
    font-size: 28px;
    margin: 0 0 0.5rem;
}
h4 {
    font-size: 18px;
    margin: 0 0 0.5rem;
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
}

button:disabled {
    opacity: 0.5;
}
.btn {
    display: inline-block;
    text-decoration: none;
    border-radius: 20px !important;
    padding: 6px 20px !important;
    vertical-align: middle;
    text-transform: none;
}
a.btn {
    line-height: 1.75;
}
.btn--action {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    color: #fff !important;
    box-shadow: none !important;
    border: 0;
    border-radius: 20px !important;
    padding: 6px 20px !important;
    font-size: 16px !important;
    text-transform: none !important;
}
.btn--action:hover {
    background-color: var(--sec-color) !important;
    border-color: var(--sec-color) !important;
}
.btn--action.MuiButton-text {
    background: none !important;
    color: var(--main-color) !important;
}
.btn--action .MuiLoadingButton-loadingIndicator {
    color: black;
}
.btn--outline {
    border-radius: 20px !important;
    text-transform: none !important;
    border: 1px solid var(--main-color) !important;
    background: transparent !important;
    color: var(--main-color) !important;
    font-size: 16px !important;
}
.btn--outline:hover {
    background: var(--main-color) !important;
    color: white !important;
}
.btn--outline-dark {
    border-radius: 20px !important;
    text-transform: none !important;
    border: 1px solid !important;
    border-color: var(--text-color);
    background: transparent !important;
    font-size: 16px !important;
    color: var(--text-color) !important;
    box-shadow: none !important;
}
.btn--outline-dark:hover {
    background: #000 !important;
    border-color: #000 !important;
    color: white !important;
}
.btn--dashed {
    border: 1px dashed !important;
}
.btn--dark {
    background: #000 !important;
    color: white !important;
}
.btn--gray {
    background: #f1f1f1 !important;
    color: black !important;
    text-transform: none !important;
    border: 0;
    border-radius: 20px !important;
}
.btn--gray:hover {
    background: #dbdbdb !important;
}
.btn--dark:hover {
    background: var(--main-color) !important;
    color: white !important;
}
.btn--empty {
    box-shadow: 0px 6px 15px 0 rgb(146 146 146 / 43%) !important;
    background: #fff;
    padding: 15px 20px;
    font-size: 16px !important;
}
.btn--empty.btn {
    text-transform: none;
}
.btn-lg {
    padding: 15px !important;
}
.btn--sm {
    border-radius: 20px !important;
    padding: 0px 15px !important;
}
.btn--tag {
    flex-shrink: 0;
    text-transform: none !important;
    line-height: 1.5 !important;
    font-size: 14px !important;
    border: 1px solid !important;
    color: #aaa !important;
}
.btn--tag.btn--tag-active {
    color: #fff !important;
    border-color: var(--main-color) !important;
}

.button-arrow-icon {
    position: absolute;
    right: 10px;
    top: calc(50% - 12px);
}

.button-prev-arrow-icon {
    position: absolute;
    left: 10px;
    top: calc(50% - 12px);
}

.product--category-tags-container {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: auto;
}

@media (max-width: 899px) {
    .product--category-tags-container {
        z-index: 1;
        position: relative;
        padding: 0 16px;
        margin: 0 -16px 8px -16px;
        flex-wrap: nowrap;
    }
}

.Mui-disabled input {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}
.no-bg {
    background: none !important;
}

.modal-alert--inner {
    min-width: 500px;
}

.w-100 {
    width: 100%;
}
.t-center {
    text-align: center;
}

/* Inputs */
.MuiInputBase-root {
    border-radius: 12px !important;
}

@media (max-width: 899px) {
    .modal-close {
        position: absolute !important;
        right: auto;
        left: 17px;
        top: 7px;
        color: var(--text-color) !important;
    }
}

/* Scroll Bar */

.MuiPopover-paper::-webkit-scrollbar {
    width: 6px;
}
.MuiPopover-paper::-webkit-scrollbar-track {
    background: transparent;
}
.MuiPopover-paper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.MuiAutocomplete-popper ::-webkit-scrollbar {
    width: 6px;
}
.MuiAutocomplete-popper ::-webkit-scrollbar-track {
    background: transparent;
}
.MuiAutocomplete-popper ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.modal-alert--wrapper {
    padding: 50px;
    min-height: 500px;
    overflow: auto;
    background-color: var(--background-color);
}
.modal-alert--wrapper::-webkit-scrollbar {
    width: 6px;
}
.modal-alert--wrapper::-webkit-scrollbar-track {
    background: transparent;
}
.modal-alert--wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.modal-alert--wrapper .modal-alert--title {
    margin-top: 0;
    margin-bottom: 30px;
}
.modal-alert--wrapper .btn--action {
    white-space: nowrap;
}
.modal-alert--buttons {
    display: flex;
    justify-content: space-between;
}
@media (max-width: 899px) {
    .modal-alert--title {
        text-align: center;
    }
}

.css-1vi79xw-MuiFormHelperText-root {
    margin-left: 0 !important;
    font-family: inherit !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    font-family: inherit !important;
}
.custom-radio {
    height: 25px;
}
.custom-radio svg {
    height: 1rem;
}
.custom-radio .MuiFormControlLabel-label {
    font-size: 16px;
    margin-left: -5px;
}
.custom-alert {
    color: black;
    background-color: rgb(255 87 34 / 10%) !important;
}
.custom-alert svg {
    color: #ff5722;
}

.minicart--addons-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    opacity: 0;
    display: none;
    transition: opacity 0.7s;
}
.cart-product-list .minicart--addons-backdrop {
    position: fixed;
    z-index: 10;
}
.minicart--addon-products-modal {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--modal-background);
    padding: 40px;
    bottom: 0;
    border-radius: 15px 15px 0 0;
    transition: transform 0.7s;
    transform: translateY(100%);
}

@media (max-width: 350px) {
    .minicart--addon-products-modal {
        padding: 20px;
    }
}

.minicart--addon-products-modal.active {
    animation-duration: 0.3s;
    transform: translateY(0);
    animation-name: slideUpModalAddon;
}
.minicart--addons-backdrop.active {
    display: block;
    opacity: 1;
    animation-duration: 0.3s;
    animation-name: showBackDrop;
}
@keyframes slideUpModalAddon {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes showBackDrop {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.minicart--addon-products__minicart-close {
    right: 30px;
    top: 33px;
    position: absolute !important;
}
.minicart--addon-products__minicart-title {
    margin-bottom: 20px;
    font-size: 20px;
}
.minicart--addon-products-modal .addon-product--mini {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product--quantity--mini {
    position: static;
    width: 98px;
}
.minicart--addon-products-modal img {
    width: 100%;
    border-radius: 4px;
}
.popup-addons-wrapper {
    margin: 0 -40px;
    padding: 0 40px;
    max-height: 450px;
    overflow-y: auto;
}

@media (max-width: 350px) {
    .popup-addons-wrapper {
        margin: 0 -20px;
        padding: 0 20px;
    }
}

.popup-addons-wrapper > div:not(:last-child) {
    margin-bottom: 4px;
}

.popup-addons-wrapper::-webkit-scrollbar {
    width: 6px;
}
.popup-addons-wrapper::-webkit-scrollbar-track {
    background: transparent;
}
.popup-addons-wrapper::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.addon-product--title-mini {
    padding: 6px;
    font-size: 14px;
    vertical-align: middle;
}
.addon-product--mini-inner {
    display: flex;
    align-items: center;
}
.addon-product--mini .addon-product--image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
}

.addon-product--mini-inner--btn {
    width: 104px;
    height: 32px;
    border-radius: 20px !important;
}

.footer-space {
    min-height: 50px;
    flex-shrink: 0;
}

.minicart--inner .bonuses-products--title {
    margin: 40px 0 10px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
}
.minicart--inner .bonus-product-choose {
    padding: 10px;
    margin: 0 20px 0px 20px;
    background: var(--background-color);
    border-radius: 15px;
}
.minicart-inner .bonus-product-choose[disabled] {
    opacity: 1;
    filter: grayscale(1);
}

.grecaptcha-badge {
    visibility: hidden;
}

.leaflet-control-attribution .leaflet-control {
    visibility: hidden;
}

.choose-town-page_logo-wrapper {
    padding: 30px 0px;
    width: 100%;
}

.choose-town-page_logo-wrapper img {
    width: 100%;
}

.scroll-to-top-btn {
    position: relative;
    margin-left: auto;
    bottom: 20px;
    font-size: 24px;
    right: 20px;
    width: 50px;
    background-color: var(--scroll-btn-background);
    color: var(--scroll-btn-color);
    text-align: center;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
}

.scroll-to-top-btn svg {
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.scroll-to-top-btn:hover {
    color: #000;
}

.screen-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    pointer-events: none;
}

.screen-footer > * {
    pointer-events: auto;
}

.text-field__text-adornment {
    margin-left: 5px;
    color: var(--main-color);
    cursor: pointer;
}

.text-field__text-adornment:hover {
    text-decoration: underline;
}

.page-title {
    margin-top: 0;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
