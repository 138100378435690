@font-face {
    font-family: "Cera";
    font-weight: 100;
    src: url("CeraRoundProLight.woff") format("woff");
}
@font-face {
    font-family: "Cera";
    font-weight: 200;
    src: url("CeraRoundPro-Light.otf") format("otf");
}
@font-face {
    font-family: "Cera";
    font-weight: 300;
    src: url("CeraRoundPro-Regular.otf") format("otf");
}
@font-face {
    font-family: "Cera";
    font-weight: 500;
    src: url("CeraRoundProMedium.woff2") format("woff2");
}
@font-face {
    font-family: "Cera";
    font-weight: 600;
    src: url("CeraRoundProBold.woff2") format("woff2");
}
