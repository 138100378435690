.deliveryClosed {
    padding: 15px;
    color: #fff;
    background-color: #000;
    text-align: center;
}
@media (max-width: 899px) {
    .deliveryClosed {
        padding: 8px;
    }
    .deliveryClosed span {
        display: block;
    }
}

.we-closed-modal {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 600px;
}

.we-closed-modal .cat-sleep-img {
    max-width: 200px;
}

@media (max-width: 899px) {
    .we-closed-modal {
        border-radius: 0px;
        height: 100%;
        min-width: unset;
    }
    .we-closed-modal .modal-close:not(.vk) {
        right: 15px;
        left: unset;
    }
}
