.install-app--panel .MuiDrawer-paper{
    border-radius: 15px 15px 0 0;
    overflow: initial;
}
.install-app--inner{
    padding: 40px 30px 30px;
    font-size: 14px;
    position: relative;
}
.install-app--title{
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 1.5rem;
}
.install-app--inner ul {
    padding-left: 1.5rem;
    margin-bottom: 30px;
}
.install-app--inner ul li {
    margin-bottom: 10px;
    padding-right: 45px;
    line-height: 1.4
}
.install-app--close {
    position: absolute;
    right: 15px;
    top: 15px;
}
.install-app--icon {
    width: 70px;
    position: absolute;
    top: -35px;
    background: white;
    border-radius: 10px;
    padding: 5px;
}