@font-face {
    font-family: "Rubik";
    src: url("Rubik-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Rubik";
    src: url("Rubik-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("Rubik-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("Rubik-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Rubik";
    src: url("Rubik-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
