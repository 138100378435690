.alert--wrong-variant {
    border: 1px solid #ffc3c2;
}

.MuiDialog-container .MuiDialog-paper {
    overflow: unset;
}

.product-modal-dialog .MuiDialog-container .MuiDialog-paper {
    border-radius: 20px;
}

.product-modal-wrapper {
    height: 100%;
    display: flex;
    overflow: hidden;
}

@media (max-width: 899px) {
    .product-modal-wrapper {
        overflow: visible;
    }
}

.modal-close {
    position: absolute !important;
    right: -60px;
    top: 0;
    color: white !important;
}

@media (max-width: 899px) {
    .product-modal-wrapper .modal-close {
        right: 15px;
        left: unset;
        background-color: var(--modal-background);
        color: var(--text-color) !important;
    }
    .product-modal-wrapper .modal-close.vk {
        left: 15px;
        right: unset;
    }
}

/* Размер приложения вк на десктопе */
@media (min-width: 899px) and (max-width: 991px) {
    .modal-close {
        position: absolute !important;
        right: 15px;
        left: unset;
        top: 7px;
        color: var(--text-color) !important;
    }
    .product-modal-wrapper .modal-close {
        left: 15px;
        right: unset;
        background-color: #fff;
    }
}

.modal-close:hover svg {
    transition: transform 0.5s;
}
.modal-close:hover svg {
    transform: rotate(180deg);
}
.product-modal {
    display: flex;
    width: 100%;
}
.product-modal--scrolling {
    overflow: auto;
    height: 500px;
    margin-top: 25px;
    padding: 0 20px 10px 20px;
}
.product-modal--scrolling::-webkit-scrollbar {
    width: 5px;
}
.product-modal--scrolling::-webkit-scrollbar-track {
    background: transparent;
}
.product-modal--scrolling::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
.product-modal .product-modal--image,
.product-modal--info {
    width: 50%;
    padding: 20px;
}
.product-modal--info {
    background-color: var(--modal-background-primary);
    border-radius: 0 20px 20px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.product-modal .product-modal--image {
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-modal .product-modal--image img {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
}
.product-modal .product-modal--title-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.product-modal .product-modal--title {
    margin: 0;
}
.product-modal .product-modal--title-info {
    margin: 5px 0 0 16px;
    cursor: pointer;
    transition: color 0.3s;
}
.product-modal .product-modal--title-info:hover {
    color: var(--main-color);
}

.product-modal--tooltip {
    padding: 8px;
    font-size: 12px;
    line-height: 1.5;
}

.product-modal--tooltip-title {
    color: #666666;
    margin-bottom: 4px;
}

.product-modal--tooltip-option {
    display: flex;
    justify-content: space-between;
}

.product-modal--description {
    color: #666;
    font-size: 14px;
}
.product-modal--buying {
    margin-top: 1rem;
    text-align: right;
    position: relative;
    height: 40px;
}

.product-modal--variations-buying {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 15px 20px;
    background-color: var(--modal-background-primary);
    border-radius: 0 0 20px 0;
    box-shadow: 0px 0px 8px rgb(6 5 50 / 12%);
    clip-path: inset(-8px 0px -8px 0px);
}

.product-modal--variations-buying .btn-buy {
    flex-basis: 50%;
}

.product-modal--price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product-modal--price {
    flex-basis: 50%;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
}
.product-modal--stats {
    display: flex;
    font-weight: 400;
    white-space: nowrap;
    line-height: 1;
}
.product-modal--stats .weight {
    position: relative;
    font-size: 14px;
    color: #aaa !important;
    margin-right: 10px;
}
.product-modal--stats .count-rolls {
    position: relative;
    font-size: 14px;
    color: #aaa !important;
}
.product-modal--quantity {
    font-size: 0;
    position: absolute;
    right: 0;
    bottom: 0px;
}
.product-modal--quantity .product-decrease,
.product-modal--quantity .product-add {
    height: 32px !important;
    width: 32px !important;
    border-radius: 50% 0 0 50%;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    border: 0;
    color: #999;
    background: var(--counter-buttons-color);
    font-size: 10px;
    vertical-align: top;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    background: var(--main-color);
    border-color: var(--main-color);
    color: white;
}
.product-modal--quantity .product-add {
    border-radius: 0 50% 50% 0;
}
.product-modal--quantity .product-decrease {
    border-radius: 50% 0 0 50%;
}
.product-modal--quantity .product-decrease:hover,
.product-modal--quantity .product-add:hover {
    background: var(--sec-color);
    border-color: var(--sec-color);
    color: white;
}
.product-modal--quantity .quantity {
    width: 34px;
    display: inline-block;
    height: 32px;
    border: 0;
    font-size: 14px;
    color: white;
    font-weight: 500;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 0;
    vertical-align: middle;
    background: var(--main-color);
    outline: none !important;
}

/* Модификаторы */

.modificator-products {
    text-align: left;
}
.modificator-products--grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2%;
}
.modificator-products .modificator-products--title {
    font-size: 18px;
    font-weight: 400;
    margin-top: 1.5rem;
}
.modificator-product {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 10px 71px;
    border-radius: 10px;
    background: var(--addon-background);
    cursor: pointer;
    margin-bottom: 0;
    position: relative;
    border: 2px solid transparent;
    transition: border 150ms ease-out 0s;
}

.modificator-product-active {
    border: 2px solid var(--main-color);
}

.modificator-product .modificator-product--image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.modificator-product .modificator-product--image img {
    width: 100%;
}
.modificator-product .modificator-product--title {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.25rem !important;
}
.modificator-product .modificator-product--price {
    font-size: 14px;
    margin-bottom: 0.5rem !important;
    font-weight: bold;
    margin-top: 0.5rem;
}
.modificator-product .btn-buy {
    border-radius: 15px !important;
    font-size: 14px !important;
    padding: 3px 15px !important;
    width: 100%;
    white-space: nowrap;
}
.modificator-product--buying {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
.modificator-product .product--quantity {
    height: 32.5px;
    position: relative;
}

.modificator-product .product--quantity .quantity {
    width: calc(100% - 64px);
    height: 32.5px;
}

.modificator-product .product--quantity .product-decrease,
.modificator-product .product--quantity .product-add {
    padding: 6px 16px !important;
}

/* Дополнения */
.addon-products {
    text-align: left;
}
.addon-products--grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2%;
}
.addon-products .addon-products--title {
    font-size: 18px;
    font-weight: 400;
    margin-top: 1.5rem;
    margin-bottom: 0px;
}
.addon-product {
    height: 100%;
    padding: 10px 10px 71px;
    border-radius: 10px;
    background: var(--addon-background);
    margin-bottom: 0;
    position: relative;
    border: 2px solid transparent;
}
.addon-product.addon-product-active {
    border: 2px solid var(--main-color);
}
.addon-product .addon-product--image {
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4px;
}
.addon-product .addon-product--image img {
    width: 100%;
    border-radius: 4px;
}
.addon-product .addon-product--title {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.25rem !important;
}
.addon-product .addon-product--price {
    font-size: 14px;
    margin-bottom: 0.5rem !important;
    font-weight: bold;
    margin-top: 0.5rem;
}
.addon-product .btn-buy {
    border-radius: 15px !important;
    font-size: 14px !important;
    padding: 3px 15px !important;
    width: 100%;
    white-space: nowrap;
}
.addon-product--buying {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
}
.addon-product .product--quantity {
    height: 32.5px;
    position: relative;
}
.addon-product .product--quantity .quantity {
    width: calc(100% - 64px);
    height: 32.5px;
}

.addon-product .product--quantity .product-decrease,
.addon-product .product--quantity .product-add {
    padding: 6px 16px !important;
}

@media (max-width: 899px) {
    .product-modal-dialog .MuiDialog-container .MuiDialog-paper {
        border-radius: 0;
    }
    .product-modal {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .product-modal .product-modal--image,
    .product-modal--info {
        border-radius: 0;
        width: 100%;
    }
    .product-modal--info {
        flex-grow: 1;
    }
    .product-modal .product-modal--title-container {
        margin-top: 15px;
    }
    .product-modal--scrolling {
        max-height: none !important;
        margin-top: 0px !important;
        flex-grow: 1;
        height: 100%;
    }

    .product-modal--variations-buying {
        border-radius: 0;
        position: sticky;
        bottom: -1px;
        z-index: 10;
        background: rgba(var(--rgb-modal-background), 0.7);
        backdrop-filter: blur(20px);
    }
}

.product-modal--attributes {
    margin-top: 15px;
}
.product-modal--attribute {
    margin-top: 10px;
}
.attribute--name {
    margin-bottom: 5px;
}
.attribute--variations {
    width: 100%;
    padding: 3px;
    background: var(--variations-backround);
    border-radius: 17px !important;
}
.attribute--variations > * {
    flex: 1;
}
.btn--variation {
    padding: 2px !important;
    border-radius: 15px !important;
    border: 0 !important;
    font-size: 14px !important;
    text-transform: none !important;
    color: var(--text-color) !important;
    opacity: 0.7;
}
.btn--variation:hover {
    background: transparent !important;
}
.Mui-selected.btn--variation {
    box-shadow: rgb(6 5 50 / 19%) 0 6px 20px;
    background: var(--selected-variation-backround) !important;
    opacity: 1;
}
