.footer {
    background: var(--footer-background);

    margin-top: 3rem;
    color: #aaa;
    font-size: 17px;
    line-height: 25.5px;
}
.footer--container {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.footer.white .footer--container {
    padding-top: 0;
    padding-bottom: 3rem;
}

.footer.white {
    background: #fff;
    color: black;
}

.footer h1,
.footer strong,
.footer b {
    color: #fff;
    line-height: 1;
}
.footer.white h1,
.footer.white strong,
.footer.white b {
    color: black;
}
.footer .about {
    margin-bottom: 3rem;
}

.footer h2,
.footer h3,
.footer h5,
.footer h6 {
    color: #fff;
}

.footer .about-title {
    font-size: 28px;
}

.footer .about-divider {
    border: 0;
    border-bottom: 1px solid #3c3b3b;
    margin-bottom: 3rem;
}

.footer .about-divider:first-child {
    display: none;
}

.footer h4 {
    font-size: 28px;
    color: white;
}
.footer.white h4 {
    font-size: 28px;
    color: black;
}

.footer a:hover {
    color: #fff;
    text-decoration: underline;
}
.footer.white a:hover {
    color: #333;
}

.footer a {
    color: #aaa;
    font-size: 16px;
    text-decoration: none;
}
.footer.white a {
    color: black;
}

.footer h4 a {
    font-size: 28px;
    color: #fff;
}
.footer.white h4 a {
    color: black;
}

.footer--logo {
    width: auto;
    height: 50px;
    max-width: none;
    margin-bottom: 0.5rem;
}

.footer--adress-list {
    margin-top: 10px;
}
.footer--adress-list-title {
    color: white;
}
.footer.white .footer--adress-list-title {
    color: black;
    font-weight: 600;
}

.footer--adress {
    font-size: 16px;
}

.footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.contacts--social-links {
    display: flex;
}

.contacts--social-links > *:not(:first-child) {
    margin-left: 6px;
}

.footer--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
}
.footer.white .footer--icon {
    background-color: black;
}

.footer--icon svg {
    width: 22px;
    height: 22px;
    color: #333;
}
.footer.white .footer--icon svg {
    color: white;
}

.footer--icon-telegram {
    width: 32px;
    height: 32px;
    color: white;
}

.footer.white .footer--icon-telegram {
    color: black;
}

.mobile-apps a {
    display: block;
    width: fit-content;
    line-height: 1;
}
.mobile-apps a:hover img {
    background-color: rgba(255, 255, 255, 0.5);
    transition: background-color 0.2s;
}

.mobile-apps img {
    max-width: 145px;
    border: 2px solid white;
    border-radius: 7px;
    margin: 10px 0 0;
}
.footer.white .mobile-apps img {
    border: 2px solid black;
    background-color: black;
}
.footer.white .mobile-apps img:hover {
    border: 2px solid black;
    background-color: #333;
}

.footer-copyright {
    margin-top: 3rem;
}

.creators {
    border: 2px dashed #444;
    line-height: 1;
    border-radius: 15px;
    padding: 9px 10px 12px 10px;
    font-size: 17px;
    color: #aaa;
    display: inline-block;
    position: relative;
}
.footer.white .creators {
    color: black;
}

.creators a:hover {
    color: var(--sec-color);
    border-color: var(--sec-color);
    text-decoration: underline;
}

.footer.white .creators a:hover {
    color: var(--sec-color);
    border-color: var(--sec-color);
}

.creators a {
    color: var(--main-color);
}
.footer.white .creators a {
    color: var(--main-color);
}
