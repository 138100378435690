/* Лист рекоммендаций в миникорзине */
.minicart-recommends {
    margin-bottom: 40px;
}
.reccomend-slide--addons-wrapper.recommend-product img {
    width: 70px !important;
    border-radius: 5px;
}
.minicart--recommends-title {
    margin: 40px 0 10px;
    padding-left: 20px;
}

.minicart-recommends .swiper {
    padding: 20px;
    margin-top: -20px;
    margin-bottom: -20px;
}
.minicart-recommends .swiper-wrapper {
    padding: 0;
    margin: 0;
}
.minicart-recommends .recommend-product {
    background: var(--background-color);
    height: 125px !important;
    box-shadow: rgb(6 5 50 / 10%) 0px 6px 20px !important;
    transition: box-shadow 150ms ease-out 0s;
}

.minicart-recommends .recommend-product:hover {
    box-shadow: rgb(6 5 50 / 10%) 0px 10px 16px -10px !important;
}

.minicart-recommends .recommend-product:active {
    box-shadow: rgb(6 5 50 / 10%) 0px 0px 0px !important;
}

.reccomend-slide--addons-wrapper.recommend-product {
    display: block;
    text-align: center;
}

.reccomend-slide {
    display: flex;
    height: auto !important;
    width: auto !important;
}

/* Лист рекоммендаций на главной */
.home-recommends {
    margin-bottom: 40px;
}

.home-recommends--title {
    margin-bottom: 0.75rem;
}

.home-recommends .swiper {
    padding-top: 20px;
    margin-top: -20px;
    padding-bottom: 20px;
    margin-bottom: -20px;
}

.home-recommends .recommend-product {
    box-shadow: var(--recommend-product-box-shadow);
    background: var(--product-background);
    transition: box-shadow 0.15s;
}

.home-recommends .recommend-product:hover {
    box-shadow: var(--product-hover-box-shadow);
}

@media (max-width: 899px) {
    .home-recommends {
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .home-recommends .swiper {
        margin-left: -16px !important;
        margin-right: -16px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

@media (min-width: 899px) {
    .home-recommends .recommend-product::before {
        box-shadow: inset 0 0 0 1px var(--product-border);
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        border-radius: inherit;
        pointer-events: none;
    }
    .home-recommends {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
    }
    .home-recommends .swiper {
        margin-left: -24px !important;
        margin-right: -24px !important;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}

.home-recommends .swiper-button-prev.swiper-button-disabled,
.home-recommends .swiper-button-next.swiper-button-disabled {
    display: none;
}

/* Продукт */

.recommend-product {
    display: flex;
    padding: 12px;
    font-size: 14px;
    border-radius: 15px;
    overflow: initial;
    cursor: pointer;
}
.recommend-product .right-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    position: relative;
    max-width: 120px;
    min-width: 80px;
}

.recommend-product .product-image {
    width: 95px;
    height: 95px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.recommend-product .product-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}

.recommend-product .product--price {
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
}

.product-name {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
