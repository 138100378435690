.delivery-address-modal--dialog .MuiDialog-container .MuiDialog-paper {
    border-radius: 20px;
}

.delivery-address-modal--wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.delivery-address-modal--wrapper .modal-close {
    color: var(--text-color) !important;
}

.delivery-address-modal--body {
    overflow: auto;
    padding: 5px 20px 16px 20px;
    margin-top: 20px;
    flex-grow: 1;
}

.delivery-address-modal--body::-webkit-scrollbar {
    width: 6px;
}
.delivery-address-modal--body::-webkit-scrollbar-track {
    background: transparent;
}
.delivery-address-modal--body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.delivery-address-modal--wrapper h3 {
    margin: 0 5px 0 0;
    color: var(--text-color);
}
.delivery-address-modal--title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
    line-height: 1.5;
}

@media (max-width: 899px) {
    .delivery-address-modal--title-container.vk {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.delivery-address-modal--map-container {
    border-radius: 20px;
    overflow: hidden;
    min-height: 400px;
}

.delivery-address-modal--buttons-container {
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 16px 20px;
    background-color: var(--modal-background);
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 0px 8px rgb(6 5 50 / 12%);
    clip-path: inset(-8px 0px px -8px 0px);
}

.delivery-address-modal--buttons-container button {
    width: 50%;
}

.delivery-address-modal--buttons-container button:first-child {
    margin-right: 16px;
}

.delivery-address-modal--buttons-container button:only-child {
    margin-right: 0;
    width: 100%;
}

.dadata-address-input {
    margin-bottom: 16px;
}

.dadata-input-container {
    position: relative;
}

.dadata-address-input input {
    border-radius: 20px;
    background-color: transparent;
    color: var(--text-color);
}

.dadata-address-input input:hover:not(:focus):not(:disabled) {
    border-color: var(--text-color) !important;
}

.dadata-address-input.error input {
    border-color: #d32f2f;
}

.dadata-address-input input:disabled {
    background: rgba(0, 0, 0, 0.05);
}

.dadata-address-input input:focus {
    box-shadow: none;
}

.dadata-address-input input:hover:not(:focus):not(:disabled) {
    border-color: #000;
}

.dadata-address-input .react-dadata__suggestions {
    padding: 8px 0;
    border-radius: 20px;
}

.dadata-address-input .react-dadata__suggestion {
    font-size: inherit;
    padding: 8px 16px;
}

.dadata-address-input .react-dadata--highlighted {
    color: inherit;
    font-weight: 700;
}

.dadata-address-input .dadata-address-fieldset {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    pointer-events: none;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
    height: 100%;
    width: 100%;
}

.dadata-address-input:has(input:focus) .dadata-address-fieldset {
    border-width: 2px;
    border-color: var(--main-color);
}

.dadata-helper-text {
    color: #d32f2f;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

@media (max-width: 899px) {
    .delivery-address-modal--dialog .MuiDialog-container .MuiDialog-paper {
        min-height: 100%;
        border-radius: 0;
    }
    .delivery-address-modal--wrapper {
        position: relative;
        overflow: visible;
        height: 100%;
    }
}

@media (max-width: 991px) {
    .delivery-address-modal--body {
        position: relative;
    }
    .delivery-address-modal--wrapper .modal-close {
        background-color: var(--modal-background);
        position: relative !important;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
}
