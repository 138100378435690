@font-face {
    font-family: "Nunito";
    src: url("Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    src: url("Nunito-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("Nunito-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("Nunito-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Nunito";
    src: url("Nunito-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}
